import React from "react";
import Layout from "../components/Layout/Layout";
import StickyFooter from "../components/Home/StickyFooter";
import IMAGES from "../img/image";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import { Link } from "react-router-dom";
import "./Reseller.css";

const PhoneSkins = () => {
  return (
    <Layout>
      <div className="reseller-container">
        <div className="reseller-section-one row">
          <div className="col-sm-12 col-12 col-md-6 col-lg-6 section">
            <img src={IMAGES.gamer} alt="" />
          </div>
          <div className="col-sm-12 col-12 col-md-6 col-lg-6 section">
            <h4>Want to earn millions of rupees in a month?</h4>
            <h4>Absolutely possible!</h4>
            <p>Come join the Saiching Reseller Program now!</p>
            <Link to="https://wa.me/918119066281" target="_blank">
              <button>Contact Now</button>
            </Link>
          </div>
        </div>
        <div className="reseller-section-two row">
          <div className="col-sm-12 col-12 col-md-6 col-lg-6 section">
            <h4>
              Sell game vouchers at low prices and get maximum profit margins.
            </h4>
            <h4>
              Be a part of Saiching Store and achieve your business success
              here!
            </h4>
          </div>
          <div className="col-sm-12 col-12 col-md-6 col-lg-6 section">
            <img src={IMAGES?.help} alt="" />
          </div>
        </div>
        <div className="reseller-section-one row">
          <div className="col-sm-12 col-12 col-md-6 col-lg-6 section features p-0">
            <div className="feature">
              <p>
                Want to do business online but don't know what products to sell
              </p>
            </div>
            <div className="feature">
              <p>Want to have additional income in between main jobs</p>
            </div>
            <div className="feature">
              <p>Have you ever tried selling online but felt like a failure?</p>
            </div>
            <div className="feature">
              <p>You are a beginner, but have the passion to succeed.</p>
            </div>
            <div className="feature">
              <p>You really want to sell game vouchers, but don't know how.</p>
            </div>
            <div className="feature">
              <p>
                Want to have a business but don't want the hassle of re-stocking
                goods
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-12 col-md-6 col-lg-6 section">
            <img src={IMAGES.think} alt="" />
            <h4>Why should you do top up and game voucher business?</h4>
            <p>
              The number of gamers in India has reached 150 million people,
              which means more than 60% of the population in India plays games.
            </p>
            <p>
              So the potential created from Top Up and Game Voucher business
              opportunities is very large and profitable.
            </p>
          </div>
        </div>
        <div className="joinus">
          <img src={IMAGES.partners} alt="" />
          <h4>Are you interested? Come join us!</h4>
          <p>
            If you are interested then you are one step closer to achieving
            business success with us. The method is very easy and you will start
            your game top up business soon!
          </p>
          <button>
            <LooksOneIcon className="icon" />
            Saicing Account Registration
          </button>
          <button>
            <LooksTwoIcon className="icon" />
            Reseller Account Upgrade
          </button>
          <button>
            <Looks3Icon className="icon" />
            Topup & start selling
          </button>
        </div>
      </div>
      <StickyFooter />
    </Layout>
  );
};

export default PhoneSkins;
