import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import "./Notification.css";
import axios from "axios";
import { message } from "antd";
import { useSelector } from "react-redux";

const Notification = () => {
  const { user } = useSelector((state) => state.user);
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(null);
  const [products, setProducts] = useState(null);

  async function getOrderNotification() {
    try {
      const res = await axios.post(
        "/api/noti/ordernotification",
        {
          email: user?.email,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        setNotification(res.data.data.reverse());
      } else {
        setNotification([]);
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    if (user) {
      getOrderNotification();
    }
  }, [user]);

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <Layout>
      <div className="notification-container">
        <h2>Notifications</h2>
        <hr className="text-white" />
        <div className="row m-0">
          {notification?.length === 0 ? (
            <>
              <p className="text-white">No notification found</p>
            </>
          ) : (
            notification?.map((elm, index) => {
              const image = products?.filter(
                (item) => item?.name === elm?.pname
              )[0]?.image;
              return (
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 p-0 p-lg-2">
                  <div className="notification">
                    <div className="image">
                      <img src={`https://saichingstore.com/${image}`} alt="" />
                    </div>
                    <div className="content">
                      <h5>Successfully Purchased</h5>
                      <p>
                        You have purchased {elm?.pname}, order number{" "}
                        {elm?.orderId}
                      </p>
                      <div className="date">
                        <span>
                          <small>
                            {new Date(elm?.createdAt).toLocaleString(
                              "default",
                              {
                                day: "numeric",
                                month: "numeric",
                                year: "numeric",
                                hour: "numeric",
                                minute: "numeric",
                                second: "numeric",
                              }
                            )}
                          </small>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Notification;
