import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import IMAGES from "../../img/image";
import "../Footer/Footer.css";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);

  return (
    <React.Fragment>
      {/* <div className="container-fluid footer-container">
        
        <div className="row">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <div className="footer-logo">
              <img width="100px" src={IMAGES.logo} alt="" />
            </div>
            <h4>Make the safest & cheapest buying for world's gamers</h4>
            <span>
              We have established official partners with many game companies and
              game studios arount the world to jointly create a safe and
              convenient recharge store for million's of players
            </span>
          </div>
          <div className="px-lg-5 quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>Quick Links</h6>
            <ul>
              {!user && (
                <>
                  <li>
                    <Link target="_blank" to="/login">Login</Link>
                  </li>
                  <li>
                    <Link target="_blank" to="/register">Register</Link>
                  </li>
                </>
              )}
              <li>
                <Link target="_blank" to="/">Home</Link>
              </li>
              <li>
                <Link target="_blank" to="/contact">Contact</Link>
              </li>
              <li>
                <Link target="_blank" to="/games">All Games</Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>Payments</h6>
            <div className="payment-channels">
              <img src={IMAGES.phonepe} alt="" />
              <img src={IMAGES.gpay} alt="" />
              <img src={IMAGES.upi} alt="" />
            </div>
          </div>
          <hr />
          <span className="text-center">
            <small>
              All Rights Reserved © 2024 | SAICHING STORE |{" "}
              <br className="d-block d-md-none d-lg-none" /> Designed &
              Developed by{" "}
              <Link
                style={{ color: "#ebef29", fontWeight: "500" }}
                target="_blank"
                to="https://aashirdigital.com"
              >
                ~aashirdigital
              </Link>
            </small>
          </span>
        </div>
      </div> */}
      <div className="footer-container">
        <div className="wa-icon-cont">
          <Link target="_blank" to="https://wa.me/918119066281">
            <WhatsAppIcon className="icon" />
          </Link>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <h5>Follow Us</h5>
            <div className="socialicons">
              <Link target="_blank" to="https://www.facebook.com/SaichingStore">
                <img src={IMAGES.facebook} alt="" />
              </Link>
              <Link
                target="_blank"
                to="https://www.youtube.com/@saichinggaming3023"
              >
                <img src={IMAGES.youtube} alt="" />
              </Link>
              <Link
                target="_blank"
                to="https://www.instagram.com/saiching_store"
              >
                <img src={IMAGES.instagram} alt="" />
              </Link>
              <Link
                target="_blank"
                to="https://whatsapp.com/channel/0029VahS9Aa0gcfM0NX1V90g"
              >
                <img src={IMAGES.whatsapp} alt="" />
              </Link>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <h5>Payment Channels</h5>
            <div className="images">
              <img src={IMAGES.upi} alt="" />
              <img src={IMAGES.phonepe} alt="" />
              <img src={IMAGES.gpay} alt="" />
            </div>
          </div>
        </div>
        <hr className="text-white" />
        <div className="contact">
          <h5>Contact Us</h5>
          <div className="contactbtn">
            <p className="m-0">
              If you need any help, please contact us by clicking "Customer
              Service" to get in touch with us.
            </p>
            <button onClick={() => navigate("/contact")}>
              Customer Service <OpenInNewIcon className="icon" />
            </button>
          </div>
        </div>
        <hr />
        <div className="footerabout">
          <div className="image">
            <img src={IMAGES.about} alt="" />
          </div>
          <div className="content">
            <h6>Saiching Store</h6>
            <p>
              Pay safe, fast and fun with <br className="d-none d-lg-block" />{" "}
              Saichingstore!
            </p>
            <span>
              Experience India's most trusted site for in-game credits.Easy,
              Fast, Secure and cheap with instant delivery. Join and get the
              latest promotion for your favourite games
            </span>
          </div>
        </div>
        <hr className="text-white" />
        <div className="importantpages">
          <Link target="_blank" to="/terms">
            Terms & conditions
          </Link>
          <Link target="_blank" to="/privacy-policy">
            Privacy policy
          </Link>
          <Link target="_blank" to="/refund-policy">
            Refund policy
          </Link>
          <span>COPYRIGHT 2024 © SAICING STORE. ALL RIGHTS RESERVED</span>
        </div>
        <hr className="text-white" />
        <div className="developercredits">
          <span>Website Developed by </span>
          <Link target="_blank" to="https://aashirdigital.com/">
            ~aashirdigital
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
