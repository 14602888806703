import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import Layout from "../components/Layout/Layout";
import HeroSection from "../components/Home/HeroSection";
import SliderText from "../components/Home/SliderText.js";
import axios from "axios";
import CancelIcon from "@mui/icons-material/Cancel";
import Games from "../components/Games";
import StickyFooter from "../components/Home/StickyFooter.js";
import NewsBanner from "../components/Home/NewsBanner.js";
import PromoEvents from "../components/Home/PromoEvents.js";
import SaichingFeatures from "../components/Home/SaichingFeatures.js";
import HomeContact from "../components/Home/HomeContact.js";
import "./Home.css";

const Home = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [notification, setNotification] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [text, setText] = useState("");
  const [promos, setPromos] = useState(null);

  async function getPromos() {
    try {
      const res = await axios.get("/api/promo/get-promos");
      if (res.data.success) {
        setPromos(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getPromos();
  }, []);

  const getUserData = async () => {
    axios
      .post(
        "/api/user/getUserData",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
        } else {
          // localStorage.removeItem("token");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  async function getNoti() {
    try {
      const res = await axios.get("/api/noti/get-noti");
      if (res.data.success) {
        setNotification(res.data.data[0]);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getUserData();
    getNoti();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowPopup(true);
    }, 5000);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "Are you sure you want quit?";
      localStorage.setItem("giveaway", "true");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  async function getSlideText() {
    try {
      const res = await axios.get("/api/banner/get-slide-text");
      if (res.data.success) {
        setText(res.data.data.text);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getSlideText();
  }, []);

  return (
    <Layout>
      {showPopup && notification?.display === "yes" && (
        <div className="popup-container">
          <div>
            <CancelIcon onClick={handleClosePopup} className="icon" />
          </div>
          <Link target="_blank" to={notification?.link}>
            <img
              src={`https://saichingstore.com/${notification?.image}`}
              alt="popup-img"
            />
          </Link>
        </div>
      )}
      <HeroSection />
      <SliderText text={text} direction={"left"} bg={"var(--p)"} fs={16} />
      <Games title={"Popular Games Top-Up"} />
      <NewsBanner />
      {promos?.length > 0 && <PromoEvents promos={promos} />}
      <SaichingFeatures />
      <HomeContact />
      {/* <StickyFooter /> */}
    </Layout>
  );
};

export default Home;
