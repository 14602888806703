import React from "react";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import "./Success.css";

const Failure = () => {
  const navigate = useNavigate();

  return (
    <div className="failure-container">
      <CancelIcon className="icon" />
      <h4>Payment Failed</h4>
      <p>
        We encountered an issue while processing your payment. Please try again.
      </p>
      <button onClick={() => navigate("/orders")}>Go to Orders</button>
    </div>
  );
};

export default Failure;
