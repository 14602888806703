import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { message } from "antd";
import axios from "axios";
import "./AdminNotification.css";

const AdminBanners = () => {
  const imageRef = useRef();
  const [banners, setBanners] = useState(null);
  const [sliderText, setSliderText] = useState("");
  const [form, setForm] = useState({
    heading: "",
    link: "",
    date: "",
    image: "",
    pimg: "",
  });

  const [fileTwo, setFileTwo] = useState(null);
  const [linkTwo, setLinkTwo] = useState("");

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "image") {
      setForm({ ...form, [name]: e.target.files[0] });
    } else if (name === "pimg") {
      setForm({ ...form, [name]: e.target.files[0] });
    } else {
      setForm({ ...form, [name]: value });
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!form?.image) {
      return message.error("Please select an image");
    }
    if (!form?.pimg) {
      return message.error("Please enter a link");
    }
    try {
      const formData = new FormData();
      formData.append("image", form?.image);
      formData.append("pimg", form?.pimg);
      formData.append("heading", form?.heading.trim());
      formData.append("date", form?.date);
      formData.append("link", form?.link.trim());

      const res = await axios.post("/api/banner/add-banner", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (res.data.success) {
        message.success(res.data.message);
        setForm({ heading: "", date: "", link: "", image: "", pimg: "" });
        getBanners();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to upload banner");
    }
  };

  const handleSubmitTwo = async (e) => {
    e.preventDefault();
    if (!fileTwo) {
      return message.error("Please select an image");
    }
    if (!linkTwo) {
      return message.error("Please enter a link");
    }
    try {
      const formData = new FormData();
      formData.append("id", "667aad96b840a12dbdec5c85");
      formData.append("image", fileTwo);
      formData.append("link", linkTwo);

      const res = await axios.post("/api/banner/add-news-banner", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (res.data.success) {
        message.success(res.data.message);
        getBanners();
        setFileTwo(null);
        setLinkTwo("");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to upload banner");
    }
  };

  async function getBanners() {
    try {
      const res = await axios.get("/api/banner/get-banners", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setBanners(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleDeleteBanner(id) {
    try {
      const res = await axios.post(
        "/api/banner/delete-banner",
        { id: id },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        getBanners();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleSaveSliderText() {
    try {
      const res = await axios.post(
        "/api/banner/slide-text",
        { text: sliderText },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getSlideText() {
    try {
      const res = await axios.get("/api/banner/get-slide-text");
      if (res.data.success) {
        setSliderText(res.data.data.text);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getBanners();
    getSlideText();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-users">
        <div className="page-title">
          <h3 className="m-0">Notification</h3>
        </div>
        <hr />
        <div className="noti-container">
          <h5 className="text-dark mb-4">Moving Text</h5>
          <div className="form-fields">
            <input
              type="text"
              name="sliderText"
              className="form-control mb-3"
              placeholder="Enter Slider Text"
              onChange={(e) => setSliderText(e.target.value)}
              value={sliderText}
            />
            <button className="mb-5" onClick={handleSaveSliderText}>
              Submit
            </button>
          </div>
          <h5 className="text-dark mb-4">Home Banners (1000 x 435)</h5>
          <div className="form-fields">
            <span className="text-dark">
              <small>Banner Img</small>
            </span>
            <input
              type="file"
              name="image"
              className="form-control mb-3"
              onChange={handleChange}
              ref={imageRef}
            />
            <span className="text-dark">
              <small>Product Img</small>
            </span>
            <input
              type="file"
              name="pimg"
              className="form-control mb-3"
              onChange={handleChange}
              ref={imageRef}
            />
            <span className="text-dark">
              <small>Heading</small>
            </span>
            <input
              type="text"
              name="heading"
              className="form-control mb-3"
              placeholder="Enter heading"
              onChange={handleChange}
              value={form?.heading}
            />
            <span className="text-dark">
              <small>Date</small>
            </span>
            <input
              type="date"
              name="date"
              className="form-control mb-3"
              onChange={handleChange}
              value={form?.date}
            />
            <span className="text-dark">
              <small>Enter Link</small>
            </span>
            <input
              type="text"
              name="link"
              className="form-control mb-3"
              placeholder="Enter link"
              onChange={handleChange}
              value={form?.link}
            />
            <button onClick={handleSubmit}>Submit</button>
          </div>
          <div className="mt-5" style={{ overflowX: "scroll" }}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Banner</th>
                  <th>Link</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {banners?.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        <img
                          width="80px"
                          src={`https://saichingstore.com/${item?.image}`}
                          alt=""
                        />
                      </td>
                      <td>{item?.link}</td>
                      <td>
                        <button
                          onClick={() => handleDeleteBanner(item?._id)}
                          className="btn btn-danger"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <hr className="text-dark my-5" />
          <h5 className="text-dark mb-4">News Banners (1000 x 435)</h5>
          <input
            type="file"
            name="image"
            className="form-control mb-3"
            onChange={(e) => setFileTwo(e.target.files[0])}
            ref={imageRef}
          />
          <input
            type="text"
            name="link"
            className="form-control mb-3"
            placeholder="Enter link"
            value={linkTwo}
            onChange={(e) => setLinkTwo(e.target.value)}
          />
          <button onClick={handleSubmitTwo}>Submit</button>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminBanners;
