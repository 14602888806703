import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import SellIcon from "@mui/icons-material/Sell";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import "./HomeContact.css";
import { Link } from "react-router-dom";

const HomeContact = () => {
  const handleLink = (link) => {
    window.open(link, "_blank");
  };

  return (
    <div className="hcc">
      <h4>Contact Us</h4>
      <p>
        If you need any help please contact us by click on the below buttons
      </p>
      <div className="cbuttons">
        <button onClick={() => handleLink("https://wa.me/918119066281")}>
          <WhatsAppIcon className="icon" />
          WhatsApp
        </button>
        <button
          onClick={() => handleLink("mailto:contact.saichingstore@gmail.com")}
        >
          <EmailIcon className="icon" />
          Email
        </button>
        <button onClick={() => handleLink("https://saichingstore.com/contact")}>
          <LocalPhoneIcon className="icon" />
          Contact
        </button>
        <button
          onClick={() => handleLink("https://saichingstore.com/reseller")}
        >
          <SellIcon className="icon" />
          Reseller
        </button>
      </div>
      {/* <h4 className="mt-5">Follow Us</h4>
      <div className="followus">
        <Link target="_blank" to="https://www.instagram.com/saiching_store">
          <InstagramIcon className="icon" />
        </Link>
        <Link target="_blank" to="https://www.facebook.com/SaichingStore">
          <FacebookIcon className="icon" />
        </Link>
        <Link
          target="_blank"
          to="https://whatsapp.com/channel/0029VahS9Aa0gcfM0NX1V90g"
        >
          <WhatsAppIcon className="icon" />
        </Link>
        <Link target="_blank" to="mailto:saichingstoreofficial@gmail.com">
          <EmailIcon className="icon" />
        </Link>
      </div> */}
    </div>
  );
};

export default HomeContact;
