import React from "react";
import IMAGES from "../../img/image";
import "./SaichingFeatures.css";

const SaichingFeatures = () => {
  const featuresArr = [
    {
      img: IMAGES.f1,
      name: "Customer Support",
      desc: "Our friendly customer support is always available to assist you.",
    },
    // {
    //   img: IMAGES.f2,
    //   name: "Localized customer support",
    //   desc: "We offer expert localization services in different languages.",
    // },
    {
      img: IMAGES.f3,
      name: "Instant delivery",
      desc: "The product you purchase will be delivered to your game account instantly after payment completed.",
    },
    {
      img: IMAGES.f4,
      name: "Smooth and reliable purchase",
      desc: "It is very safe to pay with the most popular local payment methods. To ensures your convenience, we have partnered with the most popular provider",
    },
    {
      img: IMAGES.f5,
      name: "Surprise Promotions",
      desc: "Join us & enjoy the latest discounts offers in your favorites games.",
    },
  ];

  return (
    <div className="saiching-features">
      <div className="headimg text-center">
        <h4>
          Saiching Store <br /> Top up games with Saichingstore with instant
          process
        </h4>
      </div>
      <div className="m-0 row features-container">
        {featuresArr?.map((item, index) => {
          return (
            <div className="features col-12 col-sm-12 col-md-6 col-lg-6 mb-3">
              <div className="ficon">
                <img src={item?.img} alt="" />
              </div>
              <div className="fcontent">
                <h5>{item?.name}</h5>
                <p>{item?.desc}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SaichingFeatures;
