const IMAGES = {
  logo: require("../img/logo.png"),
  // payments
  paytm: require("../img/payments/paytm.png"),
  phonpe: require("../img/payments/phonepe.png"),
  gpay: require("../img/payments/gpay.png"),
  wallet: require("../img/payments/wallet.png"),
  // features
  f1: require("../img/home/f1.png"),
  f2: require("../img/home/f2.png"),
  f3: require("../img/home/f3.png"),
  f4: require("../img/home/f4.png"),
  f5: require("../img/home/f5.png"),
  // footer
  flashimg: require("../img/home/flash-sale.png"),
  paytm: require("../img/home/paytm.webp"),
  gpay: require("../img/home/gpay.webp"),
  phonepe: require("../img/home/phonepe.webp"),
  upi: require("../img/home/upi.webp"),
  gamer: require("../img/gamer.webp"),
  help: require("../img/help.png"),
  think: require("../img/think.png"),
  partners: require("../img/partners.png"),
  coin: require("../img/coin.png"),
  facebook: require("../img/footer/facebook.png"),
  instagram: require("../img/footer/instagram.png"),
  whatsapp: require("../img/footer/whatsapp.png"),
  youtube: require("../img/footer/youtube.png"),
  about: require("../img/footer/about.png"),
};

export default IMAGES;
