import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./SearchContainer.css";
import { useDispatch, useSelector } from "react-redux";
import { setSearch } from "../redux/features/searchSlice";

const SearchContainer = () => {
  const { search } = useSelector((state) => state.search);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [products, setProducts] = useState(null);
  const [query, setQuery] = useState("");
  const [filterProduct, setFilterProduct] = useState(null);
  const [noResult, setNoResult] = useState(false);

  const handleFilterProduct = (e) => {
    const searchText = e.target.value.toLowerCase();
    setQuery(searchText);
  };

  useEffect(() => {
    const filteredProducts = products?.filter((item) =>
      item.name.toLowerCase().includes(query)
    );
    setFilterProduct(filteredProducts);

    // Check for no results
    setNoResult(query !== "" && filteredProducts?.length === 0);
  }, [query, products]);

  const getAllProducts = async () => {
    try {
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data.reverse());
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <div className={`search-container ${search ? "active" : ""}`}>
      <div className="search-tool">
        <SearchIcon className="icon" />
        <input
          placeholder="Search"
          type="text"
          onChange={handleFilterProduct}
        />
        <CloseIcon
          style={{ cursor: "pointer" }}
          className="icon"
          onClick={() => dispatch(setSearch(!search))}
        />
      </div>
      <hr />
      {query !== "" && (
        <div className="search-box">
          {filterProduct &&
            filterProduct?.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    dispatch(setSearch(!search));
                    navigate(`/product/${item?.name}`);
                  }}
                  key={index}
                  className="product search-product"
                >
                  <img src={`https://saichingstore.com/${item.image}`} alt="" />
                  <h6>{item?.name}</h6>
                </div>
              );
            })}
          <span className="text-center">
            {noResult && <h2>No Result Found</h2>}
          </span>
        </div>
      )}
    </div>
  );
};

export default SearchContainer;
