import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import { useSelector } from "react-redux";
import axios from "axios";
import { message } from "antd";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./Query.css";
import StickyFooter from "../components/Home/StickyFooter";

const Query = () => {
  const { user } = useSelector((state) => state.user);
  const [tab, setTab] = useState(0);
  const [queries, setQueries] = useState(null);
  const [singleQuery, setSingleQuery] = useState(null);
  const [msg, setMsg] = useState("");

  async function handleSubtmit(id) {
    try {
      const res = await axios.post("/api/contact/update-query", {
        id: id,
        msg: msg,
        person: "user",
      });
      if (res.data.success) {
        message.success(res.data.message);
        getUserQuery();
        setTab(0);
        setMsg("");
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getUserQuery() {
    try {
      const res = await axios.post("/api/contact/get-user-query", {
        email: user?.email,
      });
      if (res.data.success) {
        setQueries(res.data.data.reverse());
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (user !== null) {
      getUserQuery();
    }
  }, [user]);

  return (
    <Layout>
      <DashboardLayout>
        <div className="title">
          {tab === 0 && (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                {queries?.map((item, index) => {
                  return (
                    <tr>
                      <td>
                        <small>{index + 1}</small>
                      </td>
                      <td>
                        <small>{item?.email}</small>
                      </td>
                      <td>
                        <small>{item?.mobile}</small>
                      </td>
                      <td>
                        <button
                          onClick={() => {
                            setTab(1);
                            setSingleQuery(item);
                          }}
                          className="register-btn p-1 bg-warning text-dark"
                        >
                          {item?.status === "seen" ? "Closed" : "View"}
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {tab === 1 && (
            <>
              <div className="back-btnn" onClick={() => setTab(0)}>
                <ArrowBackIcon className="icon" />
                Back
              </div>
              <div className="query-reply-container">
                {singleQuery?.msg?.map((item, index) => {
                  return (
                    <div
                      className={`query-msg ${
                        item?.person === "user" && "active"
                      }`}
                    >
                      {item?.msg}
                    </div>
                  );
                })}
              </div>
              {singleQuery.status === "pending" && (
                <textarea
                  onChange={(e) => setMsg(e.target.value)}
                  className="my-3 form-control"
                  name="msg"
                  rows="4"
                ></textarea>
              )}

              {singleQuery.status === "seen" ? (
                <span className="text-danger">
                  Admin Has Closed this ticket
                </span>
              ) : (
                <button
                  onClick={() => handleSubtmit(singleQuery?._id)}
                  className="register-btn mt-3"
                >
                  Submit
                </button>
              )}
            </>
          )}
        </div>
        <StickyFooter />
      </DashboardLayout>
    </Layout>
  );
};

export default Query;
