import React from "react";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "./Success.css";

const Success = () => {
  const navigate = useNavigate();

  return (
    <div className="success-container">
      <CheckCircleIcon className="icon" />
      <h4>Payment Successful!</h4>
      <p>
        Thank you for your payment. Your transaction has been completed
        successfully.
      </p>
      <button onClick={() => navigate("/orders")}>Go to Orders</button>
    </div>
  );
};

export default Success;
