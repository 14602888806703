import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Timer.css";

const Timer = ({ event, timeLeft, setTimeLeft }) => {
  useEffect(() => {
    if (event) {
      const eventDate = new Date(`${event.date}T${event.time}`);
      const timer = setInterval(() => {
        const now = new Date();
        const timeDifference = eventDate - now;
        if (timeDifference > 0) {
          const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
          setTimeLeft({ days, hours, minutes, seconds });
        } else {
          clearInterval(timer); // Clear the interval once the countdown ends
        }
      }, 1000);
      return () => clearInterval(timer); // Clean up the interval on component unmount
    }
  }, [event]);

  return (
    <div>
      <div className="event-container">
        <div className="event-content">
          <h5>{event?.heading}</h5>
          <h1>{event?.name}</h1>
          <div className="counterr">
            <span>{timeLeft?.days}d</span>
            <span>{timeLeft?.hours}h</span>
            <span>{timeLeft?.minutes}m</span>
            <span>{timeLeft?.seconds}s</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timer;
