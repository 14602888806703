import React, { useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import WalletIcon from "@mui/icons-material/Wallet";
import "./StickyFooter.css";
import { setSearch } from "../../redux/features/searchSlice";

const StickyFooter = () => {
  const { user } = useSelector((state) => state.user);
  const { search } = useSelector((state) => state.search);
  const dispatch = useDispatch();

  return (
    <div className="sticky-footer">
      <Link to="/">
        <div className="footer-item">
          <HomeIcon className="icon" />
          Home
        </div>
      </Link>
      <Link onClick={() => dispatch(setSearch(!search))}>
        <div className="footer-item">
          <SearchIcon className="icon" />
          Search
        </div>
      </Link>
      {user && (
        <Link to="/wallet">
          <div className="footer-item">
            <WalletIcon className="icon" />
            Wallet
          </div>
        </Link>
      )}
      {user ? (
        <Link to="/user-dashboard">
          <div className="footer-item">
            <AccountCircleIcon className="icon" />
            Account
          </div>
        </Link>
      ) : (
        <Link to="/login">
          <div className="footer-item">
            <AccountCircleIcon />
            Login
          </div>
        </Link>
      )}
    </div>
  );
};

export default StickyFooter;
