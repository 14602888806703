import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/features/userSlice";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import GroupIcon from "@mui/icons-material/Group";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import VpnLockIcon from "@mui/icons-material/VpnLock";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import HistoryIcon from "@mui/icons-material/History";
import WalletIcon from "@mui/icons-material/Wallet";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import "./SideMenu.css";
import IMAGES from "../../img/image";
import getUserData from "../../utils/userDataService.js";

const SideMenu = ({ sideMenu, setSideMenu }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [submenu, setSubmenu] = useState(false);
  const [balance, setBalance] = useState("");

  useEffect(() => {
    getUserData(null, null, setBalance);
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
    dispatch(setUser(null));
  };
  return (
    <div className={`sidemenu-container ${sideMenu ? "active" : ""}`}>
      <div className="sidemenu">
        <HighlightOffIcon
          onClick={() => setSideMenu(!sideMenu)}
          className="close-icon"
        />
        {!user && (
          <>
            <h4 className="text-white mb-4">Sign Up today!</h4>
            <ul className="no-user-list">
              <button
                onClick={() => {
                  navigate("/register");
                  setSideMenu(!sideMenu);
                }}
                className="theme-btn"
              >
                Sign Up, It's Free
              </button>
              <button
                onClick={() => {
                  navigate("/login");
                  setSideMenu(!sideMenu);
                }}
                className="theme-btn bg-white mt-2"
              >
                Sign In
              </button>
            </ul>
          </>
        )}

        {user && (
          <>
            <div className="profile">
              <AccountCircleIcon className="icon" />
              <div>
                Hi! {user?.fname}
                <br />
                <small>
                  Joined{" "}
                  {new Date(user?.created).toLocaleString("default", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </small>
              </div>
            </div>
            {user && (
              <div className="wallet-balance">
                <div className="wallet" onClick={() => navigate("/wallet")}>
                  <p>MY BALANCE</p>
                  <KeyboardArrowRightIcon className="icon" />
                </div>
                <div className="wallet">
                  <div className="balance">
                    <AccountBalanceWalletIcon className="walleticon" />₹
                    {balance}
                  </div>
                  <button
                    className="topupbtn"
                    onClick={() => navigate("/wallet")}
                  >
                    TOP UP
                  </button>
                </div>
              </div>
            )}
            <ul className="p-0 user-menus">
              <li
                className={`${
                  location.pathname === "/user-dashboard" && "active"
                }`}
                onClick={() => {
                  setSideMenu(!sideMenu);
                  navigate("/user-dashboard");
                }}
              >
                <DashboardIcon className="me-2 icon" />
                Dashboard
              </li>
              <li
                className={`${location.pathname === "/my-account" && "active"}`}
                onClick={() => {
                  setSideMenu(!sideMenu);
                  navigate("/my-account");
                }}
              >
                <AccountBoxIcon className="me-2 icon" />
                My Profile
              </li>
              <li
                className={`${location.pathname === "/orders" && "active"}`}
                onClick={() => {
                  setSideMenu(!sideMenu);
                  navigate("/orders");
                }}
              >
                <HistoryIcon className="me-2 icon" />
                Order History
              </li>
              <li
                className={`${location.pathname === "/reseller" && "active"}`}
                onClick={() => {
                  setSideMenu(!sideMenu);
                  navigate("/reseller");
                }}
              >
                <GroupIcon className="me-2 icon" />
                Join Reseller
              </li>

              <li
                className={`${location.pathname === "/query" && "active"}`}
                onClick={() => {
                  setSideMenu(!sideMenu);
                  navigate("/query");
                }}
              >
                <QuestionMarkIcon className="me-2 icon" />
                Customer Query
              </li>
              <li onClick={handleLogout}>
                <LogoutIcon className="me-2 icon" />
                Logout
              </li>
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

export default SideMenu;
