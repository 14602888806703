import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import "./PromoEvents.css";

const PromoEvents = ({ promos }) => {
  const navigate = useNavigate();

  var settings = {
    dots: true,
    className: "center",
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="promo-event-container">
      <h2>Promo & Events</h2>
      <div className="promo-blogs-container">
        <Slider {...settings}>
          {promos?.map((item, index) => (
            <div className="promoo">
              <div
                className="blog"
                onClick={() => navigate(`/promo/${item._id}`)}
                key={index}
              >
                <img src={`https://saichingstore.com/${item?.image}`} alt="" />
                <div className="content">
                  <span>
                    {new Date(item?.date).toLocaleString("default", {
                      day: "numeric",
                      long: "long",
                      year: "numeric",
                    })}{" "}
                    | {item?.category}
                  </span>
                  <h5>{item?.title}</h5>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default PromoEvents;
