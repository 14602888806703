import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import "./AdminDashboard.css";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import StayCurrentPortraitIcon from "@mui/icons-material/StayCurrentPortrait";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import HelpIcon from "@mui/icons-material/Help";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Chart as ChartJS } from "chart.js/auto";
import { Bar, Doughnut, Line } from "react-chartjs-2";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [data, setData] = useState(null);
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState(null);
  const [queries, setQueries] = useState(null);
  const [total, setTotal] = useState(0);
  const [topUsers, setTopUsers] = useState(null);
  const [toggle, setToggle] = useState(true);
  const [totalOrders, setTotalOrders] = useState(null);
  const [totalSales, setTotalSales] = useState(null);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState("");
  const [monthlyOrdersData, setMonthlyOrdersData] = useState({
    labels: [],
    data: [],
  });

  const [monthlySalesData, setMonthlySalesData] = useState({
    labels: [],
    data: [],
  });

  // ======================================== MAINTENANCE
  // ======================================== MAINTENANCE
  // ======================================== MAINTENANCE
  async function handleMaintenance() {
    try {
      setLoading(true);
      const res = await axios.post(
        "/api/admin/update-website",
        { email: "admin@gmail.com" },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        setToggle(true);
        setLoading(false);
        getWebsite();
      } else {
        message.error(res.data.message);
        setLoading(false);
        setToggle(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setToggle(false);
    }
  }

  async function getWebsite() {
    try {
      const res = await axios.get("/api/admin/get-website");
      if (res.data.success) {
        setToggle(res.data.data.website);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getWebsite();
  }, []);
  // ======================================== MAINTENANCE
  // ======================================== MAINTENANCE
  // ======================================== MAINTENANCE

  const getDashboard = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/admin/get-dashboard", {
        params: { fromDate, toDate },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setData(res.data.data);
        setOrders(res.data.orders);
        setProducts(res.data.products);
        setQueries(res.data.queries);
        setTotal(res.data.total);
        setTopUsers(res.data.topUsers);
        setTotalOrders(res.data.totalOrders);
        setTotalSales(res.data.totalSales);

        const { monthlyOrders, monthlySales } = res.data;

        // Update state with fetched data
        setMonthlyOrdersData({
          labels: monthlyOrders.labels,
          data: monthlyOrders.data,
        });

        setMonthlySalesData({
          labels: monthlySales.salesLabels,
          data: monthlySales.salesData,
        });

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSearch = () => {
    if (!fromDate || !toDate) {
      message.error("Please select both from and to dates");
      return;
    }
    getDashboard();
  };

  // useEffect(() => {
  //   const currentDate = new Date(); // Get current date
  //   const firstDateOfMonth = new Date(
  //     currentDate.getFullYear(),
  //     currentDate.getMonth(),
  //     1
  //   );
  //   const lastDateOfMonth = new Date(
  //     currentDate.getFullYear(),
  //     currentDate.getMonth() + 1,
  //     0
  //   );

  //   getDashboard(firstDateOfMonth, lastDateOfMonth);
  // }, []);

  return (
    <AdminLayout>
      <div className="page-title">
        <h3 className="m-0">Dashboard</h3>
        <div className={`toggle-icon`} onClick={handleMaintenance}>
          <div className={`circle ${toggle && "active"}`}>
            {loading && (
              <div className="spinner-grow spinner-grow-sm" role="status">
                <span className="sr-only"></span>
              </div>
            )}
          </div>
        </div>
      </div>
      <hr />
      <div className="filter-container">
        <div className="field">
          <input
            onChange={(e) => setFromDate(e.target.value)}
            className="form-control"
            type="date"
            name="fromDate"
          />
        </div>
        <div className="field">
          <input
            onChange={(e) => setToDate(e.target.value)}
            className="form-control"
            type="date"
            name="fromDate"
          />
        </div>
        <button onClick={handleSearch} className="search-btn">
          Search
        </button>
        {loading ? (
          <div class="spinner-grow spinner-grow-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="chart-container">
        <div className="chartOne">
          <h4>Total Orders</h4>
          <div className="hr-line"></div>
          <Bar
            data={{
              labels: monthlyOrdersData.labels,
              datasets: [
                {
                  label: "Total Orders",
                  data: monthlyOrdersData.data,
                  backgroundColor: "#ffca00",
                  borderWidth: 0,
                },
              ],
            }}
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
        </div>
        <div className="chartTwo">
          <h4>Total Sales</h4>
          <div className="hr-line"></div>
          <Line
            data={{
              labels: monthlySalesData.labels,
              datasets: [
                {
                  label: "Total Sales",
                  data: monthlySalesData.data,
                  fill: false,
                  borderColor: "rgba(75,192,192,1)",
                  borderWidth: 2,
                  pointRadius: 3,
                  pointBackgroundColor: "rgba(75,192,192,1)",
                  pointBorderColor: "rgba(75,192,192,1)",
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: "rgba(75,192,192,1)",
                  pointHoverBorderColor: "rgba(75,192,192,1)",
                },
              ],
            }}
            options={{
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
          />
        </div>
      </div>
      <div className="admin-dashboard-container p-0">
        <div className="dash-card" onClick={() => navigate("/admin-orders")}>
          <div className="count">
            <h1 className="m-0">
              {loading ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <b>{totalOrders || 0}</b>
              )}
            </h1>
            <span className="title">Total Orders</span>
          </div>
          <PointOfSaleIcon className="icon" />
        </div>
        <div className="dash-card" onClick={() => navigate("/admin-products")}>
          <div className="count">
            <h1 className="m-0">
              {loading ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <b>{products?.length || 0}</b>
              )}
            </h1>
            <span className="title">Total Products</span>
          </div>
          <StayCurrentPortraitIcon className="icon" />
        </div>
        <div className="dash-card" onClick={() => navigate("/admin-payments")}>
          <div className="count">
            <h1 className="m-0">
              {loading ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <b>{totalSales || 0}</b>
              )}
            </h1>
            <span className="title">Total Sales</span>
          </div>
          <MonetizationOnIcon className="icon" />
        </div>
        <div className="dash-card" onClick={() => navigate("/admin-queries")}>
          <div className="count">
            <h1 className="m-0">
              <h1 className="m-0">
                {loading ? (
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <b>
                    {queries?.filter((item) => {
                      return item.status === "pending";
                    }).length || 0}
                  </b>
                )}
              </h1>
            </h1>
            <span className="title">Queries</span>
          </div>
          <HelpIcon className="icon" />
        </div>
      </div>
      <div className="admin-recent-things">
        <div className="recent-orders">
          <h5>Recent Orders</h5>
          <hr />
          <table className="table ">
            <thead>
              <tr>
                <th>Order Id</th>
                <th>Email</th>
                <th>Total</th>
                <th>Date</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {data
                ?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <small>{item?.orderId}</small>
                      </td>
                      <td>
                        <small>{item?.customer_email}</small>
                      </td>
                      <td>
                        <small>{item?.price}</small>
                      </td>
                      <td>
                        <small>
                          {new Date(item?.createdAt).toLocaleString("default", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                        </small>
                      </td>
                      <td>
                        <RemoveRedEyeIcon
                          onClick={() =>
                            navigate(`/admin-view-order/${item?.orderId}`)
                          }
                          className="text-success icon"
                        />
                      </td>
                    </tr>
                  );
                })
                .slice(0, 5)}
            </tbody>
          </table>
        </div>
        <div className="recent-queries">
          <h5>Recent Queries</h5>
          <hr />
          <table className="table ">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {queries &&
                queries
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <small>{item?.name}</small>
                        </td>
                        <td>
                          <small>{item?.email}</small>
                        </td>
                        <td>
                          <small>{item?.mobile}</small>
                        </td>
                        <td>
                          <button
                            className="register-btn p-1"
                            onClick={() => navigate("/admin-queries")}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })
                  .slice(0, 5)}
            </tbody>
          </table>
        </div>
      </div>
      {/* <div className="top-buyer">
        <div className="page-title">
          <h5 className="m-0">Top Buyer</h5>
        </div>
        <hr />
        <table className="table">
          <thead>
            <tr>
              <th>Sr No</th>
              <th>Email</th>
              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {topUsers?.map((item, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item?._id}</td>
                  <td>{item?.totalSpent}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div> */}
    </AdminLayout>
  );
};

export default AdminDashboard;
