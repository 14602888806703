import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { message } from "antd";
import axios from "axios";
import "./AdminFlashSale.css";

const AdminEvent = () => {
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [event, setEvent] = useState("");
  const [flashProducts, setFlashProducts] = useState("");
  const [product, setProduct] = useState({
    heading: "",
    subHeading: "",
    price: "",
    ogPrice: "",
    link: "",
    stock: "",
  });

  // timer
  function handleChange(e) {
    setEvent({ ...event, [e.target.name]: e.target.value });
  }
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const res = await axios.post("/api/admin/add-event", event, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const res = await axios.get("/api/admin/get-event", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        setEvent(res.data.event);
      } catch (error) {
        console.log(error);
      }
    };

    fetchEvent();
  }, []);

  // product
  function handleFileChange(e) {
    setFile(e.target.files[0]);
  }
  function handleProductChange(e) {
    setProduct({ ...product, [e.target.name]: e.target.value });
  }

  async function handleAddProduct() {
    try {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("heading", product?.heading);
      formData.append("subHeading", product?.subHeading);
      formData.append("price", product?.price);
      formData.append("ogPrice", product?.ogPrice);
      formData.append("link", product?.link);
      formData.append("stock", product?.stock);

      const res = await axios.post("/api/flash/add-product", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        setProduct({
          heading: "",
          subHeading: "",
          price: "",
          ogPrice: "",
          link: "",
          stock: "",
        });
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        getProducts();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
    }
  }

  async function getProducts() {
    try {
      const res = await axios.get("/api/flash/get-product", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setFlashProducts(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
    }
  }

  async function handleDelete(id, image) {
    try {
      const res = await axios.post(
        "/api/flash/delete-product",
        { id: id, image: image },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        setFlashProducts(res.data.data);
        getProducts();
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
    }
  }

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">Countdown</h3>
        </div>
        <hr />
        {/* Time */}
        <h4>Timer</h4>
        <div className="flash-container">
          <div className="w-100 form-fields text-dark">
            <input
              className="form-control"
              type="date"
              name="date"
              required
              value={event?.date}
              onChange={handleChange}
            />
          </div>
          <div className="w-100 form-fields text-dark">
            <input
              className="form-control"
              type="time"
              name="time"
              required
              value={event?.time}
              onChange={handleChange}
            />
          </div>
          <div className="w-100 form-fields text-dark">
            <select
              name="display"
              onChange={handleChange}
              className="form-select"
              value={event?.display}
            >
              <option value="">Select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <button onClick={handleSubmit} className="btn btn-dark">
            Save
          </button>
        </div>
        {/* Products   */}
        <h4 className="prodhead">Products</h4>
        <div className="flash-products">
          <div className="form-fields text-dark">
            <input
              className="w-100 form-control"
              aria-label="Select Image"
              type="file"
              accept=".jpg, .jpeg, .png, .webp"
              name="image"
              required
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </div>
          <div className="form-fields text-dark">
            <input
              className="w-100 form-control"
              placeholder="Enter heading"
              type="text"
              name="heading"
              required
              value={product?.heading}
              onChange={handleProductChange}
            />
          </div>
          <div className="form-fields text-dark">
            <input
              className="w-100 form-control"
              placeholder="Enter sub heading"
              type="text"
              name="subHeading"
              required
              value={product?.subHeading}
              onChange={handleProductChange}
            />
          </div>
          <div className="form-fields text-dark">
            <input
              className="w-100 form-control"
              placeholder="Enter price"
              type="text"
              name="price"
              required
              value={product?.price}
              onChange={handleProductChange}
            />
          </div>
          <div className="form-fields text-dark">
            <input
              className="w-100 form-control"
              placeholder="Enter og price"
              type="text"
              name="ogPrice"
              required
              value={product?.ogPrice}
              onChange={handleProductChange}
            />
          </div>
          <div className="form-fields text-dark">
            <input
              className="w-100 form-control"
              placeholder="Enter product link"
              type="text"
              name="link"
              required
              value={product?.link}
              onChange={handleProductChange}
            />
          </div>
          <div className="form-fields text-dark">
            <input
              className="w-100 form-control"
              placeholder="Enter stock"
              type="text"
              name="stock"
              value={product?.stock}
              onChange={handleProductChange}
              required
            />
          </div>
          <button onClick={handleAddProduct} className="btn btn-dark">
            Save
          </button>
        </div>

        <h4 className="prodhead">Products</h4>
        <div className="productss">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Srno</th>
                <th>Image</th>
                <th>Heading</th>
                <th>Sub Heading</th>
                <th>Price</th>
                <th>Og Price</th>
                <th>Link</th>
                <th>Stock</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {flashProducts &&
                flashProducts?.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>
                        <img
                          src={`https://saichingstore.com/${item?.image}`}
                          width="40px"
                          alt=""
                        />
                      </td>
                      <td>{item?.heading}</td>
                      <td>{item?.subHeading}</td>
                      <td>{item?.price}</td>
                      <td>{item?.ogPrice}</td>
                      <td>{item?.link}</td>
                      <td>{item?.stock}</td>
                      <td>
                        <button
                          onClick={() => handleDelete(item?._id, item?.image)}
                          className="btn btn-danger"
                        >
                          delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminEvent;
