import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import DashboardLayout from "./components/DashboardLayout";
import { useSelector } from "react-redux";
import IMAGES from "../img/image";
import axios from "axios";
import { message } from "antd";
import AddCardIcon from "@mui/icons-material/AddCard";
import getUserData from "../utils/userDataService.js";
import "./Wallet.css";
import StickyFooter from "../components/Home/StickyFooter.js";

const Wallet = () => {
  const { user } = useSelector((state) => state.user);
  const [tab, setTab] = useState(1);
  const [btn, setBtn] = useState(0);
  const [form, setForm] = useState({ email: "", amount: "" });
  const [payments, setPayments] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [history, setHistory] = useState(null);
  const [historyData, setHistoryData] = useState(null);
  const [payBtn, setPayBtn] = useState(null);
  const [balance, setBalance] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [filteredPayments, setFilteredPayments] = useState("");

  useEffect(() => {
    getUserData(null, null, setBalance);
  }, []);

  useEffect(() => {
    const filteredpay = data?.filter((item) => {
      const itemDate = new Date(item.createdAt);
      const selected = new Date(selectedDate);
      return (
        itemDate.getDate() === selected.getDate() &&
        itemDate.getMonth() === selected.getMonth() &&
        itemDate.getFullYear() === selected.getFullYear()
      );
    });
    setPayments(filteredpay);
  }, [selectedDate]);

  useEffect(() => {
    const filteredHis = historyData?.filter((item) => {
      const itemDate = new Date(item.created);
      const selected = new Date(selectedDate);
      return (
        itemDate.getDate() === selected.getDate() &&
        itemDate.getMonth() === selected.getMonth() &&
        itemDate.getFullYear() === selected.getFullYear()
      );
    });
    setHistory(filteredHis);
  }, [selectedDate]);

  async function getUserWalletHistory() {
    try {
      const res = await axios.post("/api/wallet/get-wallet-history", {
        email: user?.email,
      });
      if (res.data.success) {
        setHistory(res.data.data.reverse());
        setHistoryData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (user !== null) {
      getUserWalletHistory();
    }
  }, [user]);

  const generateOrderId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const randomNum = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number
    const orderId = `${year}${month}${day}${hours}${minutes}${seconds}${randomNum}`;
    setOrderId(orderId);
  };

  useEffect(() => {
    generateOrderId();
  }, []);

  async function payUpiOne() {
    if (form?.amount < 1) {
      return message.error("Enter amount greater than 20");
    }
    if (form?.amount > 2000) {
      return message.error("You cannot add money more than 2000");
    }
    try {
      // setLoading(true);
      setPayBtn(true);
      const paymentObject = {
        order_id: orderId,
        txn_amount: form?.amount,
        txn_note: "Wallet Topup Note",
        product_name: "Wallet Topup",
        customer_name: form?.customer_name,
        customer_email: form?.customer_email,
        customer_mobile: form?.customer_mobile,
        callback_url: `https://saichingstore.com/api/payment/status?orderId=${orderId}`,
      };
      const response = await axios.post(
        "/api/payment/addmoney",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (response.data.success && response.data.data.status) {
        window.location.href = response.data.data.results.payment_url;
        setLoading(false);
        setTimeout(() => {
          setPayBtn(false);
        }, 1500);
      } else {
        console.log(response.data.message);
        message.error(response.data.message);
        setPayBtn(false);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
      setLoading(false);
      setPayBtn(false);
    }
  }

  async function getUserPayments() {
    try {
      const res = await axios.post("/api/payment/get-user-payments", {
        email: user?.email,
      });
      if (res.data.success) {
        setPayments(res.data.data.reverse());
        setData(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setForm({ ...form, [e.target.name]: e.target.value });
    if (name === "amount") {
      if (value < 20) {
        setError(true);
      } else {
        setError(false);
      }
    }
  }

  useEffect(() => {
    if (user?.email) {
      setForm((prev) => ({
        ...prev,
        customer_email: user?.email,
        customer_name: user?.fname,
        customer_mobile: user?.mobile,
      }));
      getUserPayments();
    }
  }, [user]);

  return (
    <Layout>
      <DashboardLayout>
        <div className="wallet-dash">
          <div className="bal w-100">
            My Balance: ₹{parseFloat(balance).toFixed(2)}
          </div>
          <div className="wallet-dash-card" onClick={() => setTab(1)}>
            Topup Balance
            <AddCardIcon className="icon ms-2" />
          </div>
          <div className="wallet-dash-card" onClick={() => setTab(0)}>
            Transaction History
          </div>
        </div>

        {tab === 0 && (
          <div className="txn-btns mb-4">
            <button
              onClick={() => setBtn(0)}
              className={`${btn === 0 && "active"}`}
            >
              Addition
            </button>
            <button
              onClick={() => setBtn(1)}
              className={`${btn === 1 && "active"}`}
            >
              Deduction
            </button>
          </div>
        )}

        {/* USER HISTORY */}
        {tab === 0 && btn === 0 && (
          <>
            <div className="tools mb-3">
              <div className="form-fields">
                <input
                  type="date"
                  className="py-2 form-control"
                  placeholder="Search Order ID"
                  name="addition"
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
              </div>
              <div className="form-fields">
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setPayments(data);
                  }}
                >
                  Clear Filter
                </button>
              </div>
            </div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Order Id</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {payments &&
                  payments.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item?.client_txn_id}</td>
                        <td>{parseFloat(item.amount).toFixed(2)}</td>
                        <td>
                          {new Date(item?.createdAt).toLocaleString("default", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          })}
                        </td>
                        <td>{item.status}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </>
        )}

        {tab === 0 && btn === 1 && (
          <>
            <div className="tools mb-3">
              <div className="form-fields">
                <input
                  type="date"
                  className="py-2 form-control"
                  placeholder="Search Order ID"
                  name="addition"
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
              </div>
              <div className="form-fields">
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    setHistory(historyData);
                  }}
                >
                  Clear Filter
                </button>
              </div>
            </div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Order ID</th>
                  <th>Balance Before</th>
                  <th>Balance After</th>
                  <th>Price</th>
                  <th>Product Info</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {history &&
                  history.map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.orderId}</td>
                        <td>{item.balanceBefore}</td>
                        <td>{item.balanceAfter}</td>
                        <td>{parseFloat(item.price).toFixed(2)}</td>
                        <td>{item.p_info}</td>
                        <td>
                          {new Date(item?.created).toLocaleString("default", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          })}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </>
        )}

        {/* BARCODE  */}
        {tab === 1 && (
          <div className="add-money">
            <div className="txn-details">
              <div className="form-fields mb-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter amount"
                  name="amount"
                  onChange={handleChange}
                  value={form?.amount}
                />
                <span className="text-danger">
                  {error && "Enter amount greater or equal to 20"}
                </span>
              </div>
              <button
                onClick={payUpiOne}
                className="w-100 register-btn mt-2"
                disabled={payBtn}
              >
                {payBtn && (
                  <div
                    className="spinner-grow spinner-grow-sm me-2"
                    role="status"
                  >
                    <span className="sr-only"></span>
                  </div>
                )}
                Pay Online
              </button>
            </div>
          </div>
        )}
        <StickyFooter />
      </DashboardLayout>
    </Layout>
  );
};

export default Wallet;
